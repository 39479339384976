import "./style.css";
import logo_banner from "../../img/logo_banner.png";

const Map = () => {
  return (
    <div className="map-container">
      <img src={logo_banner} className="logo_banner" alt="logo" />
    </div>
  );
};

export default Map;
